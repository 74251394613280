export const crmTeamConfig: any = {
  SELF_GEN_LEADS: {
    id: 115,
    name: 'Self-Gen Leads',
  },
  TRIM_LEADS: {
    id: 109,
    name: 'Trim Leads',
  },
  LEAD_GENERATION: {
    id: 12,
    name: 'Lead Generation',
  },
  SHOWROOM: {
    id: 18,
    name: 'SHOWROOM',
  },
  SHOWROOM_STAGE: {
    id: 136,
    name: 'SHOWROOM STAGE', // preselect sold
  },

  AWAITING_HOWNER_VISIT: {
    id: 137,
    name: 'AWAITING HOWNER VISIT',
  },
  HOWNER_FOLLOW_UP: {
    id: 138,
    name: 'HOWNER FOLLOW UP',
  },

  RENEWAL: {
    id: 17,
    name: '3G RADIO REPLACEMENT',
  },
  RENEWAL_TO_DO: {
    id: 101,
    name: 'TO DO',
  },
  FIRST_ATTEMPT: {
    id: 131,
    name: 'FIRST ATTEMPT',
  },
  SECOND_ATTEMPT: {
    id: 132,
    name: 'SECOND ATTEMPT',
  },
  THIRD_ATTEMPT: {
    id: 133,
    name: 'HIRD ATTEMPT',
  },
  FOURTH_ATTEMPT: {
    id: 130,
    name: 'FOURTH ATTEMPT',
  },
  AWAITING_HOWNER_VISIT_LIVE: {
    id: 137,
    name: 'AWAITING HOWNER VISIT',
  },
  HOWNER_FOLLOW_UP_LIVE: {
    id: 138,
    name: 'HOWNER FOLLOW UP',
  },
  PHONE_SALES: {
    id: 8,
    name: 'PHONE SALES',
  },
  ONIT_HOME_SALES: {
    id : 36,
    name : 'ONIT HOME'
  },
  DEALER_PROGRAM: {
    id: 50,
    name: 'Authorized Partner Program'
  }
};
