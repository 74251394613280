export const docusignTemplates = {
  HP_ALARM_TEMPLATE: '2018 HP Alarm Contract (app)',
  HP_ALARM_APP_NO_REPSIGN_TEMPLATE: '2021 HP Alarm Contract (app) - no sales rep sign',
  HP_ALARM_SMARTPAY_TEMPLATE: '2020 HP Alarm Contract (Smartpay)',
  HP_ALARM_FORTIVA_TEMPLATE: '2020 HP Alarm Contract (fortiva)',
  HP_ALARM_THREEG_RENEWAL_TEMPLATE: '2021 HP Alarm Contract (3g Renewal)',
  HP_ALARM_SHOWROOM_TEMPLATE: '2021 HP Alarm Contract (show room)',
  HP_ALARM_SHOWROOM_NO_REPSIGN_TEMPLATE: '2021 HP Alarm Contract (show room) - no sales rep sign',
  HP_ALARM_THREEG_RENEWAL_NO_REPSIGN_TEMPLATE: '2021 HP Alarm Contract (3g Renewal )- no sales rep sign',
  HP_ALARM_FORTIVA_NO_REPSIGN_TEMPLATE: '2020 HP Alarm Contract (fortiva) - no sales rep sign',
  HP_ALARM_SMARTPAY_NO_REPSIGN_TEMPLATE: '2020 HP Alarm Contract (Smartpay) - no sales rep sign',
  HP_ALARM_DEALER_TEMPLATE: '2025 HP Alarm Contract (authorized partner security)',
};
